<template>
  <div class="card">
    <h3 class="card-title">LISTAR LICENCIAS</h3>
    <div v-if="licencias">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="licencias"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="showLicencia"
      ></table-list>
    </div>
    <modal v-if="openNew" @close="openNew = false">
      <nueva-licencia slot="modal-body" @saved="saved"></nueva-licencia>
    </modal>
    <modal v-if="openShow" @close="closeShow">
      <show-licencia
        slot="modal-body"
        @close="closeShow"
        @deleted="refresh"
        @edit="showEdit"
        :licencia="licenciaSelected"
      ></show-licencia>
    </modal>
    <modal v-if="openEdit" @close="closeEdit">
      <editar-licencia
        slot="modal-body"
        @close="closeEdit"
        @saved="refresh"
        :licencia="licenciaSelected"
      ></editar-licencia>
    </modal>

    <Modal v-if="showAddGuardia" @close="showAddGuardia = false">
      <CreateGuardia
        :desde="selectedDesdeGuardia"
        :hasta="selectedHastaGuardia"
        slot="modal-body"
        @saved="guardiaSaved"
      />
    </Modal>
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import { LicenciasServices } from "../services/LicenciasServices";
import Modal from "@/components/elements/Modal";
import NuevaLicencia from "./NuevaLicencia.vue";
import EditarLicencia from "./EditarLicencia.vue";
import ShowLicencia from "./ShowLicencia.vue";
import CreateGuardia from "../../guardias/views/CreateGuardia.vue";
import Swal from "sweetalert2";
export default {
  name: "ListarLicencias",
  components: {
    CreateGuardia,
    TableList,
    Modal,
    ShowLicencia,
    EditarLicencia,
    NuevaLicencia,
  },
  data() {
    return {
      licencias: null,
      headers: LicenciasServices.headers(),
      body: LicenciasServices.body(),
      actionButton: {
        text: "Nueva licencia",
        action: () => {
          this.openNew = true;
        },
      },
      openNew: false,
      openShow: false,
      openEdit: false,
      showAddGuardia: false,
      selectedDesdeGuardia: null,
      selectedHastaGuardia: null,
      licenciaSelected: null,
    };
  },
  mounted() {
    LicenciasServices.api.getAll().then((licencias) => (this.licencias = licencias));
  },
  methods: {
    refresh() {
      this.openNew = false;
      this.openShow = false;
      this.openEdit = false;
      LicenciasServices.api.getAll().then((licencias) => {
        this.licencias = licencias;
        this.$refs["tablelist"].refresh(licencias);
      });
    },
    saved(reemplazoGuardia){
      if(reemplazoGuardia !== null){
        Swal.fire("Atención", `<p>El empleado al que se le cargó la licencia estaba de guardia, por favor seleccione un reemplazo para sus días de licencia.</p>`, "warning");
        this.selectedDesdeGuardia = reemplazoGuardia.desde
        this.selectedHastaGuardia = reemplazoGuardia.hasta
        this.showAddGuardia = true
      }
      this.refresh()
    },
    showLicencia(licencia) {
      this.licenciaSelected = licencia;
      this.openShow = true;
    },
    showEdit(licencia){
      this.licenciaSelected = licencia
      this.openShow = false
      this.openEdit = true
    },
    closeShow() {
      this.openShow = false
      this.licenciaSelected = null
    },
    closeEdit() {
      this.openEdit = false
      this.licenciaSelected = null;
    },
    guardiaSaved(){
      this.showAddGuardia = false
    }
  },
};
</script>
