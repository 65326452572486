<template>
  <form style="width: 800px; max-width: 100%" @submit.prevent="save">
    <h3 class="card-title">Cargar guardia</h3>
    <div class="cols2">
      <suggest
        id="empleado"
        label="Empleado"
        placeholder="Buscar empleado.."
        :data="personas"
        :items="personaList"
        @selected="empleadoSelected"
        :required="enviado && !empleado"
      ></suggest>
    </div>
    <div class="cols2">
      <pd-input
        v-model="guardia.guardia_desde"
        id="desde"
        label="Fecha inicio guardia"
        placeholder="Fecha de inicio de guardia"
        type="date"
        :required="enviado && !guardia.guardia_desde"
      ></pd-input>
      <pd-input
        v-model="guardia.guardia_hasta"
        id="hasta"
        label="Fecha finalización guardia"
        placeholder="Fecha de finalización de guardia"
        type="date"
        :required="enviado && !guardia.guardia_hasta"
      ></pd-input>
    </div>
    <button class="btn btn-primary mt-2" type="submit">Guardar</button>
  </form>
</template>
<script>
import { PersonasService } from '../../personas/services/PersonasServices';
import Suggest from '@/components/elements/Suggest';
import GuardiasServices from '../services/GuardiaServices';
import Swal from 'sweetalert2';
import Vuex from "vuex";
export default {
  name: 'CreateGuardia',
  components: {
    Suggest,
  },
  props:{
    desde: {
      required: false,
      default: null
    },
    hasta: {
      required: false,
      default: null
    }
  },
  data() {
    return {
      enviado: false,
      guardia: {
        empleado_id: null,
        area_id: null,
        gerencia_id: null,
        guardia_desde: null,
        guardia_hasta: null,
        cargado_por: null
      },
      areasConGuardia: [
        0,
        2, //Acueducto RC
        6, //Acueducto Oeste
        7, //Oficina técnica
        9, //Servicios generales
      ],
      empleado: null,
      personaList: PersonasService.dataList(),
      personas: [],
    };
  },
  computed: {
    ...Vuex.mapState(["user"]),
  },
  mounted() {
    console.log(this.user)
    if(this.$props.desde){
      this.guardia.guardia_desde = this.$props.desde
    }
    if(this.$props.hasta){
      this.guardia.guardia_hasta = this.$props.hasta
    }
    PersonasService.api
      .getAllGuardias()
      .then((personas) => (this.personas = personas));
  },
  methods: {
    save() {
      this.enviado = true;
      if (
        !this.guardia.empleado_id ||
        !this.guardia.guardia_desde ||
        !this.guardia.guardia_hasta
      ) {
        return;
      }
      if(this.checkDesdeHasta() === false){
        return;
      }
      this.guardia.cargado_por = this.user.persona_id
      GuardiasServices.api
        .addGuardia(this.guardia)
        .then(async (r) => {
          if (r.success === true) {
            await Swal.fire('Éxito', `<p>Se guardó con éxito.</p>`, 'success');
            this.$emit("saved", r);
          } else {
            Swal.fire('Error', `<p>El empleado al que se le cargó la guardia está de licencia, por favor seleccione un reemplazo.</p>`, 'error');
          }
        })
        .catch((e) => {
          Swal.fire('Error', `<p>${error.response?.data?.message ||
          error.response?.data?.error ||
          error?.message ||
          error}</p>`, 'error');
        });
    },
    empleadoSelected(empleado) {
      if (empleado) {
        this.guardia.empleado_id = empleado.id;
        this.guardia.gerencia_id = empleado.gerencia_id;
        this.guardia.area_id = empleado.area_id;
        this.empleado = empleado;
      }
    },
    validateDate(date) {
      let controlDate = new Date("01-01-2000"); //dd-mm-YYYY
      if (date) {
        let newDate = new Date(date) ?? null; //dd-mm-YYYY
        if (newDate > controlDate) {
          return true;
        }
      }
      return false;
    },
    checkDesdeHasta() {
      let desde = this.validateDate(this.guardia.guardia_desde) ? new Date(this.guardia.guardia_desde) : null;
      let hasta = this.validateDate(this.guardia.guardia_hasta) ? new Date(this.guardia.guardia_hasta) : null;
      if (desde && hasta) {
        if (desde > hasta) {
          Swal.fire(
            "Error",
            "La fecha de inicio no puede ser mayor a la fecha de finalización",
            "error"
          );
          return false
        }
      }
    },
  },
};
</script>
